import React from "react";
import Pagination from "../../Pagination";
import Post from "../../Post";
import Spacing from "../../Spacing";
import { Link } from "react-router-dom";
import { IMAGE_BASE_URL } from "../../../lib/api";
export default function BlogSectionStyle2({ data }) {
  return (
    <div className="container">
      <div className="row cs_row_gap_50">
        {data?.map(
          (
            {
              blog_title,
              blog_image,
              blog_date,
              blog_slug,
              variant,
              socialShare,
              btnText,
              blog_short_des,
              blog_thumbnail,
            },
            index
          ) => (
            <div className="col-xl-3 col-md-6" key={index}>
              <div className={`cs_post cs_style_1 ${variant}`}>
                <Link
                  to={`/blog/${blog_slug}`}
                  className="cs_post_thumb cs_view_mouse"
                >
                  <img src={IMAGE_BASE_URL + blog_thumbnail} alt={blog_title} />
                </Link>
                <div className="cs_post_info">
                  <div>
                    <h2 className="cs_post_title cs_semibold cs_fs_32">
                      <Link to={`/blog/${blog_slug}`}>{blog_title}</Link>
                    </h2>
                    <p class="cs_iconbox_text">{blog_short_des}</p>
                    <div className="cs_posted_by">{blog_date} - Shine32</div>
                  </div>
                  {btnText && (
                    <div className="cs_heading_color cs_medium">
                      <Link to={`/blog/${blog_slug}`} className="cs_post_btn">
                        {btnText}
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )
        )}
      </div>
      <Spacing md="110" lg="70" />
      {/* <Pagination /> */}
    </div>
  );
}
