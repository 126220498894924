import { Icon } from "@iconify/react";
import React from "react";
import { Link } from "react-router-dom";
import { IMAGE_BASE_URL } from "../../lib/api";

export default function Post({
  blog_title,
  blog_image,
  blog_date,
  blog_slug,
  variant,
  socialShare,
  btnText,
  blog_short_des,
}) {
  return (
    <div className={`cs_post cs_style_1 ${variant}`}>
      <Link to={`/blog/${blog_slug}`} className="cs_post_thumb cs_view_mouse">
        <img src={IMAGE_BASE_URL + blog_image} alt={blog_title} />
      </Link>
      <div className="cs_post_info">
        <div>
          <div className="cs_posted_by">{blog_date} - Shine32</div>
          <p class="cs_iconbox_text">{blog_short_des}</p>
          <h2 className="cs_post_title cs_semibold cs_fs_32">
            <Link to={`/blog/${blog_slug}`}>{blog_title}</Link>
          </h2>
        </div>
        {btnText && (
          <div className="cs_heading_color cs_medium">
            <Link to={`/blog/${blog_slug}`} className="cs_post_btn">
              {btnText}
            </Link>
          </div>
        )}
      </div>
    </div>
  );
}
