import React from "react";
import Spacing from "../../Spacing";
import parse from "html-react-parser";

export default function AboutSectionStyle6({
  imgUrl,
  spiningImgUrl,
  titleUp,
  title,
  subTitle,
}) {
  return (
    <div className="cs_about cs_style_1 cs_type_1 cs_shape_wrap">
      <div className="cs_shape_1 cs_position_4" />
      <div className="container">
        <div className="row cs_gap_y_50 justify-content-center">
          <div className="col-lg-4">
            <div className="cs_about_img">
              <img src={imgUrl} alt="About" />
              {/* <div className="cs_about_mini_img">
                <img
                  src={spiningImgUrl}
                  alt="Mini"
                  className="cs_rotate_animation"
                />
                <img
                  src="/images/icons/tick.png"
                  alt="Right"
                  className="cs_right_icon"
                />
              </div> */}
            </div>
          </div>
          <div className="col-lg-4">
            <div className="cs_about_content">
              <div className="cs_section_heading cs_style_1">
                <Spacing md="5" />
                <h2 className="" style={{
                  border: "1px solid #307bc4",
                  padding: "2px 5px",
                  backgroundColor: "#307bc4",
                  color: "#FFF"
                }}>{title}</h2>
                <h4 style={{
                  color: "#307bc4"
                }} className="mb-2"><b>BDS</b></h4>
                <Spacing md="20" xl="50" />
                <p className="mb-3">Dentist,Implantologist,Dental Surgeon
                  12 Years Experience Overall  (11 years as specialist)</p>
                <p className="mb-3">Dr. Sukirti Mandal Biswas is a Dentist, Implantologist and Dental Surgeon in Gariahat Market, Kolkata and has an experience of 5 years in these fields. Dr. Sukirti Mandal Biswas practices at Shine 32 in Gariahat Market, Kolkata and Shine 32 in Barrackpore, Kolkata. She completed BDS from Rungta College of Dental Sciences Research in 2012 and Diplomate in Endodontics & Implantology from Rungta College of Dental Sciences Research in 2016.</p>
                <p className="mb-3">She is a member of Indian Dental Association. Some of the services provided by the doctor are: BPS Dentures Fixing, Teeth Scaling and Polishing, Surgical Tooth Extraction, Dental prophylaxis and Crowns and Bridges Fixing etc</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
