import React, { useContext } from "react";
import BannerSectionStyle5 from "../Section/BannerSection/BannerSectionStyle5";
import BannerSectionStyle4 from "../Section/BannerSection/BannerSectionStyle4";
import TeamSectionStyle2 from "../Section/TeamSection/TeamSectionStyle2";
import Section from "../Section";
import { pageTitle } from "../../helpers/PageTitle";
import AwardSection from "../Section/AwardSection";
import MyContext from "../../lib/MyContext";

export default function Treatments() {
  const { allTreatmentsApiData } = useContext(MyContext);
  pageTitle("Doctors");
  return (
    <>
      <BannerSectionStyle5
        bgUrl="/images/doctors/banner_bg.svg"
        imgUrl="/images/doctors/banner_img.png"
        title="Introduce You to <br />Our Experts"
        subTitle="The list of certified doctors with years of <br />professional experiences"
      />
      {/* Start Award Section */}

      <Section topMd={85} topLg={140} topXl={100}>
        <AwardSection
          sectionTitle="Our Treatments"
          data={allTreatmentsApiData}
        />
      </Section>
      {/* End Award Section */}
      <Section className="cs_footer_margin_0">
        <BannerSectionStyle4
          bgUrl="images/doctors/banner_bg_2.png"
          title="Don’t Let Your Health <br />Take a Backseat!"
          subTitle="Schedule an appointment with one of our experienced <br />medical professionals today!"
        />
      </Section>
    </>
  );
}
