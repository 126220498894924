import React, { useRef } from "react";
import SectionHeading from "../../SectionHeading";
import Spacing from "../../Spacing";
import TeamStyle4 from "../../Team/TeamStyle4";
import Slider from "react-slick";
import IconBoxStyle10 from "../../IconBox/IconBoxStyle10";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
import { IMAGE_BASE_URL } from "../../../lib/api";

export default function TeamSectionStyle4({
  sectionTitle,
  sectionTitleUp,
  data,
}) {
  const sliderRef = useRef(null);

  const next = () => {
    sliderRef.current.slickNext();
  };

  const previous = () => {
    sliderRef.current.slickPrev();
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: data.lenght > 3 ? 4 : 3,
    slidesToScroll: 1,
    arrows: false,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1700,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <div className="container">
      <SectionHeading title={sectionTitle} titleUp={sectionTitleUp} center />
      <Spacing md="72" lg="50" />
      <div className="row cs_gap_y_30">
        <Slider ref={sliderRef} {...settings} className="cs_slider_activate">
          {data?.map(
            (
              { image, alt_tag, name, post, description, href, social },
              index
            ) => (
              <div key={index}>
                <div className="cs_team cs_style_1 text-center cs_radius_20 overflow-hidden">
                  <Link
                    to={href}
                    className="cs_member_img d-flex align-items-center justify-content-center"
                  >
                    <img src={IMAGE_BASE_URL + image} alt={alt_tag} />
                  </Link>
                  <div className="cs_team_meta cs_white_bg">
                    <div>
                      <h3 className="cs_member_name cs_fs_30">
                        <Link>{name}</Link>
                      </h3>
                      <p className="cs_member_designation cs_fs_20 cs_heading_color">
                        {post}
                      </p>
                      {/* <p className="cs_member_description">{description}</p> */}
                    </div>
                    {/* <div>
                    <div className="cs_social_links">
                      {social?.map((item, index) => (
                        <Link to={item.href} key={index}>
                          <Icon icon={item.icon} />
                        </Link>
                      ))}
                    </div>
                  </div> */}
                  </div>
                </div>
              </div>
            )
          )}
        </Slider>
        <div className="cs_hero_info_col d-flex align-items-center justify-content-center">
          <Link to="#" className="cs_btn cs_style_1">
            <span>View More</span>
            <i>
              <img src="/images/icons/arrow_white.svg" alt="Icon" />
              <img src="/images/icons/arrow_white.svg" alt="Icon" />
            </i>
          </Link>
        </div>
        {/* {data?.map((item, index) => (
          <div className="col-lg-4" key={index}>
            <TeamStyle4 {...item} />
          </div>
        ))} */}
      </div>
    </div>
  );
}
