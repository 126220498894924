import React from "react";
import { Outlet } from "react-router-dom";
import Header from "../Header";
import FooterStyle4 from "../Footer/FooterStyle4";
import MyContext from "../../lib/MyContext";
import SEO from "../Seo";

export default function Layout({ businessApiData }) {
  // console.log("businessApiData1213: ", businessApiData);
  return (
    <>
      <SEO
        title={businessApiData?.meta_title}
        description={businessApiData?.meta_description}
        name="Shine32"
        keywords={businessApiData?.meta_keyword}
        author={businessApiData?.meta_author}
        type="Doctor Website"
      />
      <Header
        logoSrc="/images/logo.png"
        variant="cs_heading_color"
        businessApiData={businessApiData}
      />
      <Outlet />
      <FooterStyle4 businessApiData={businessApiData} />
    </>
  );
}
