import React, { useRef, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import axios from "axios";
import Swal from "sweetalert2";


export default function AppointmentForm() {
  const [loading, setLoading] = useState(false)
  const form = useRef();
  const onSubmit = async (e) => {
    setLoading(true)
    e.preventDefault();
    const { name, email, subject, phone, query_message } = e.target.elements;

    let conFom = {
      name: name?.value,
      email: email?.value,
      subject: subject?.value,
      phone: phone?.value,
      query_message: query_message?.value,
    };

    // console.log("conFom: ", conFom);

    if (conFom.name !== "" && conFom.email !== "") {
      const url = 'https://cms.shine32.in/api/contact';
      let fData = new FormData();
      fData.append('name', name?.value);
      fData.append('email', email?.value);
      fData.append('subject', subject?.value);
      fData.append('phone', phone?.value);
      fData.append('query_message', query_message?.value);
      setLoading(true)
      axios.post(url, fData).then((response) => {
        setLoading(false)
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Submit Successfully',
          showConfirmButton: true,
          timer: 1500
        })
        form.current.reset();

      }).catch((error) => {
        setLoading(false)
        console.log(error);
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'something went wrong',
        })
      });
    } else {
      setLoading(false)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'please fill all the field!',
      })
    }
  };
  return (
    <form className="row"
      ref={form}
      onSubmit={onSubmit}>
      <div className="col-lg-6">
        <label className="cs_input_label cs_heading_color">Name</label>
        <input type="text" className="cs_form_field" placeholder="David John"
          name='name'
        />
        <div className="cs_height_42 cs_height_xl_25" />
      </div>
      <div className="col-lg-6">
        <label className="cs_input_label cs_heading_color">Phone Number</label>
        <input
          type="text"
          className="cs_form_field"
          placeholder="(123) 456 - 789"
          name='phone'

        />
        <div className="cs_height_42 cs_height_xl_25" />
      </div>
      <div className="col-lg-6">
        <label className="cs_input_label cs_heading_color">Email</label>
        <input
          type="text"
          className="cs_form_field"
          placeholder="Enter your email"
          name='email'

        />
        <div className="cs_height_42 cs_height_xl_25" />
      </div>
      <div className="col-lg-6">
        <label className="cs_input_label cs_heading_color">
          Sunject
        </label>
        <input
          type="text"
          className="cs_form_field"
          placeholder="Enter your subject"
          name='subject'

        />
        <div className="cs_height_42 cs_height_xl_25" />
      </div>

      <div className="col-lg-12">
        <label className="cs_input_label cs_heading_color">Message</label>
        <textarea
          cols={30}
          rows={10}
          className="cs_form_field"
          placeholder="Write something..."
          defaultValue={''}
          name='query_message'
        />
        <div className="cs_height_42 cs_height_xl_25" />
      </div>


      <div className="col-lg-12">
        <button className="cs_btn cs_style_1">
          <span>{loading ? "Submitting..." : "Submit Now"}</span>
          <i>
            <img src="/images/icons/arrow_white.svg" alt="Icon" />
            <img src="/images/icons/arrow_white.svg" alt="Icon" />
          </i>
        </button>
      </div>
    </form>
  );
}
