import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import Section from "../Section";
import Breadcrumb from "../Breadcrumb";
import BannerSectionStyle9 from "../Section/BannerSection/BannerSectionStyle9";
import { Icon } from "@iconify/react";
import Spacing from "../Spacing";
import Post from "../Post";
import Sidebar from "../Sidebar";
import AuthorWidget from "../Widget/AuthorWidget";
import CommentsWidget from "../Widget/CommentsWidget";
import ReplyWidget from "../Widget/ReplyWidget";
import { pageTitle } from "../../helpers/PageTitle";
import MyContext from "../../lib/MyContext";
import { IMAGE_BASE_URL } from "../../lib/api";
import SEO from "../Seo";
const tags = [
  { tag: "Emergency", href: "/blog/blog-details" },
  { tag: "Pediatric", href: "/blog/blog-details" },
  { tag: "Cardiology", href: "/blog/blog-details" },
  { tag: "Psychiatry", href: "/blog/blog-details" },
  { tag: "Others", href: "/blog/blog-details" },
];
const relatedBlog = [
  {
    title:
      "The Importance of Mental Health: Understanding and Managing Anxiety Disorders",
    thumbUrl: "/images/blog/post_1.jpeg",
    date: "March 12",
    btnText: "Learn More",
    href: "/blog/blog-details",
  },
  {
    title: `A Parent's Guide to Childhood Vaccinations: What You Need to Know`,
    thumbUrl: "/images/blog/post_2.jpeg",
    date: "March 11",
    btnText: "Learn More",
    href: "/blog/blog-details",
  },
  {
    title: "Preventing Heart Disease: Tips for a Heart-Healthy Lifestyle",
    thumbUrl: "/images/blog/post_3.jpeg",
    date: "March 9",
    btnText: "Learn More",
    href: "/blog/blog-details",
  },
];

export default function BlogDetails() {
  const { blogApiData } = useContext(MyContext);
  const [blogTagArray, setBlogTagArray] = useState([]);
  // const [blogApiData, setBlogApiData] = useState([]);
  const { blog_slug } = useParams();
  const blogFilter = blogApiData?.find((blog) => blog.blog_slug === blog_slug);
  // console.log("blogFilter: ", blogFilter);
  // console.log("blogTagArray: ", blogTagArray);
  useEffect(() => {
    // Convert the string to an array when the component mounts
    setBlogTagArray(blogFilter?.blog_tag?.split(","));
  }, [blogFilter]);
  pageTitle(blogFilter ? blogFilter?.meta_title : "");
  return (
    <>
      <SEO
        title={blogFilter?.meta_title}
        description={blogFilter?.meta_des}
        name="Shine32"
        keywords={blogFilter?.meta_keyword}
        author={blogFilter?.meta_author}
        type="Doctor Website"
      />
      <Section topMd={85} bottomMd={54} bottomLg={54}>
        <Breadcrumb title={blogFilter?.blog_title} />
      </Section>

      <div className="container">
        <div className="cs_blog_details_info">
          <div className="cs_blog_details_info_left">
            <div className="cs_blog_details_tags d-flex align-items-center">
              {blogTagArray?.map((item, index) => (
                <Link key={index} to="">
                  {item}
                </Link>
              ))}
              <div className="cs_posted_by">
                {blogFilter?.blog_date} - Shine32
              </div>
            </div>
          </div>
        </div>
        <Spacing md="55" />
        <img
          src={IMAGE_BASE_URL + blogFilter?.blog_image}
          alt="Blog Details"
          className="w-100 cs_radius_20"
        />
        <Spacing md="90" lg="50" />
        <div className="row">
          <div className="col-lg-8">
            <div className="cs_blog_details">
              <div
                dangerouslySetInnerHTML={{
                  __html: blogFilter?.blog_description,
                }}
              ></div>

              <blockquote
                style={{
                  backgroundImage: `url(${IMAGE_BASE_URL + blogFilter?.thumbnail
                    })`,
                }}
              >
                <p>“{blogFilter?.blog_quote}”</p>
              </blockquote>
              <div
                dangerouslySetInnerHTML={{ __html: blogFilter?.description }}
              ></div>
            </div>
          </div>
          <div className="col-lg-4">
            <Sidebar />
          </div>
        </div>
      </div>
    </>
  );
}
