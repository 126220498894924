import React, { useState, useEffect, useContext } from "react";
import FaqSectionStyle3 from "../Section/FaqSection/FaqSectionStyle3";
import Section from "../Section";
import {
  IMAGE_BASE_URL,
  websiteSettingApi,
  allHeadingApi,
} from "../../lib/api";
import MyContext from "../../lib/MyContext";
import { pageTitle } from "../../helpers/PageTitle";
const CookiesPolicy = () => {
  const [loading, setLoading] = useState(false);

  const {
    allTreatmentsApiData,
    homeApiData,
    aboutApiApiData,
    testimonialApiData,
    ourTeamApiData,
    blogApiData,
    faqsApiData,
  } = useContext(MyContext);

  pageTitle("Home");
  const [websiteSettingApiData, setWebsiteSettingApiData] = useState([]);
  const [allHeadingApiData, setAllHeadingApiData] = useState([]);
  useEffect(() => {
    // Function to fetch data from both APIs
    const fetchData = async () => {
      setLoading(true);
      try {
        // Calling businessApi and homeApi simultaneously
        const [websiteSettingData, allHeadingData] = await Promise.all([
          websiteSettingApi(),
          allHeadingApi(),
        ]);
        // Updating state with the API data
        setWebsiteSettingApiData(websiteSettingData[0]);
        setAllHeadingApiData(allHeadingData[0]);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching data:", err);
      }
    };

    fetchData();
  }, []);
  console.log("allHeadingApiData: ", allHeadingApiData);
  return (
    <>
      <div
        className=""
        style={{
          backgroundImage: `url(${
            IMAGE_BASE_URL + allHeadingApiData?.website_cookies_banner
          }`,
          height: "50vh",
        }}
      ></div>
      <Section topMd={85} topLg={145} topXl={105}>
        <FaqSectionStyle3
          data={faqsApiData}
          sectionTitle="Usually Asked"
          sectionTitleUp="What People"
          sectionTitleDown=""
          sectionSubTitle=""
        />
      </Section>
    </>
  );
};

export default CookiesPolicy;
