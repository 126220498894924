import React from "react";
import TextWidget from "../Widget/TextWidget";
import ContactInfoWidget from "../Widget/ContactInfoWidget";
import SocialWidget from "../Widget/SocialWidget";
import MenuWidget from "../Widget/MenuWidget";
import NewsletterStyle4 from "../Widget/NewsletterStyle4";
const menuDataOne = [
  { title: "Home", href: "/" },
  { title: "About Us", href: "/about" },
  { title: "Our Treatments", href: "/our-treatments" },
  { title: "Awards", href: "/awards" },
  { title: "Blog", href: "/blog" },
  { title: "Contact", href: "/Blog" },
];

const menuDataTwo = [
  { title: "FAQs", href: "/faq" },
  { title: "Cookies Policy ", href: "/cookiesPolicy" },
  { title: "Privacy Policy", href: "/privacyPolicy" },
  { title: "Termsand Conditions", href: "/termsandConditions" },
];

export default function FooterStyle4({ businessApiData }) {
  return (
    <footer className="cs_footer cs_style_2 cs_type_1 cs_accent_bg cs_white_color">
      <div className="container">
        <NewsletterStyle4
          title="Be Our Subscribers"
          subTitle="Join our newsletter to keep up to date <br />health from our experts"
        />
        <div className="cs_footer_in">
          <div className="cs_footer_col">
            <div className="cs_footer_item">
              <TextWidget logoUrl="/images/Logo_W.png" text="Shine32" />
              <ContactInfoWidget businessApiData={businessApiData} />
            </div>
          </div>
          <div className="cs_footer_col">
            <div className="cs_footer_item">
              <MenuWidget data={menuDataOne} />
            </div>
          </div>
          <div className="cs_footer_col">
            <div className="cs_footer_item">
              <MenuWidget data={menuDataTwo} />
            </div>
          </div>
          <div className="cs_footer_col">
            <div className="cs_footer_item">
              {/* <MenuWidget data={menuDataThree} /> */}
              <SocialWidget />
            </div>
          </div>
        </div>
      </div>
      <div className="cs_footer_bottom">
        <div className="container">
          <div className="cs_copyright">
            Copyright © 2023 Shine32. All rights reserved.
          </div>
        </div>
      </div>
    </footer>
  );
}
