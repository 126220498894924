import React, { useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Layout from "./components/Layout";
import Layout2 from "./components/Layout/Layout2";
import Layout3 from "./components/Layout/Layout3";
import Layout4 from "./components/Layout/Layout4";
import Home from "./components/Pages/Home";
import HomeStyle2 from "./components/Pages/HomeStyle2";
import HomeStyle3 from "./components/Pages/HomeStyle3";
import HomeStyle4 from "./components/Pages/HomeStyle4";
import About from "./components/Pages/About";
import Doctors from "./components/Pages/Doctors";
import Blog from "./components/Pages/Blog";
import Appointments from "./components/Pages/Appointments";
import Departments from "./components/Pages/Departments";
import DepartmentDetails from "./components/Pages/DepartmentDetails";
import BlogDetails from "./components/Pages/BlogDetails";
import DoctorDetails from "./components/Pages/DoctorDetails";
import PricingPlan from "./components/Pages/PricingPlan";
import Gallery from "./components/Pages/Gallery";
import Timetable from "./components/Pages/Timetable";
import Contact from "./components/Pages/Contact";
import { useEffect } from "react";
import ErrorPage from "./components/Pages/ErrorPage";
import Layout5 from "./components/Layout/Layout5";
import HomeStyle5 from "./components/Pages/HomeStyle5";
import HomeStyle6 from "./components/Pages/HomeStyle6";
import Treatments from "./components/Pages/Treatments";
import MyContext from "./lib/MyContext";
import Faq from "./components/Pages/Faq";
import CookiesPolicy from "./components/Pages/CookiesPolicy";
import PrivacyPolicy from "./components/Pages/PrivacyPolicy";
import TermsandConditions from "./components/Pages/TermsandConditions";
import TreatmentDetalis from "./components/Pages/TreatmentDetalis";
import Awards from "./components/Pages/Awards";
import { HelmetProvider } from 'react-helmet-async';

import {
  businessApi,
  homeApi,
  testimonialApi,
  faqsApi,
  blogApi,
  howItWorkApi,
  packageApi,
  howItSignupApi,
  calltoactionApi,
  allTreatmentsApi,
  aboutApi,
  ourTeamApi,
  galleryApi,
  seoApi
} from "./lib/api";

function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const [modalShow, setModalShow] = useState(false);
  const [businessApiData, setBusinessApiData] = useState([]);
  const [homeApiData, setHomeApiData] = useState([]);
  const [testimonialApiData, setTestimonialApiData] = useState([]);
  const [faqsApiData, setFaqsApiData] = useState([]);
  const [blogApiData, setBlogApiData] = useState([]);
  const [calltoactionApiData, setCalltoactionApiData] = useState([]);
  const [howItWorkApiData, setHowItWorkApiData] = useState([]);
  const [packageSubscriptionApiData, setPackageSubscriptionApiData] = useState(
    []
  );
  const [packageTrialApiData, setPackageTrialApiData] = useState([]);
  const [howItSignupApiData, setHowItSignupApiData] = useState([]);
  const [allTreatmentsApiData, setAllTreatmentsApiData] = useState([]);
  const [aboutApiApiData, setAboutApiApiData] = useState([]);
  const [ourTeamApiData, setOurTeamApiData] = useState([]);
  const [galleryData, setGalleryData] = useState([]);
  const [seoData, setSeoData] = useState([]);
  const [loading, setLoading] = useState(false);

  const myData = {
    loading: loading,
    allTreatmentsApiData: allTreatmentsApiData,
    businessApiData: businessApiData,
    homeApiData: homeApiData,
    aboutApiApiData: aboutApiApiData,
    testimonialApiData: testimonialApiData,
    ourTeamApiData: ourTeamApiData,
    blogApiData: blogApiData,
    faqsApiData: faqsApiData,
    galleryData: galleryData,
    seoData: seoData,
  };

  useEffect(() => {
    // Function to fetch data from both APIs
    const fetchData = async () => {
      setLoading(true);
      try {
        // Calling businessApi and homeApi simultaneously
        const [
          businessData,
          homeData,
          testimonialData,
          faqsData,
          blogData,
          // calltoactionData,
          // howItWorkData,
          // packageData,
          // howItSignupData,
          allTreatmentsData,
          aboutData,
          ourTeamData,
          galleryData,
          seoData,
        ] = await Promise.all([
          businessApi(),
          homeApi(),
          testimonialApi(),
          faqsApi(),
          blogApi(),
          // calltoactionApi(),
          // howItWorkApi(),
          // packageApi(),
          // howItSignupApi(),
          allTreatmentsApi(),
          aboutApi(),
          ourTeamApi(),
          galleryApi(),
          seoApi()
        ]);

        // Updating state with the API data
        setBusinessApiData(businessData[0]);
        setHomeApiData(homeData[0]);
        setTestimonialApiData(testimonialData);
        setFaqsApiData(faqsData);
        setBlogApiData(blogData);
        // setCalltoactionApiData(calltoactionData[0]);
        // setHowItWorkApiData(howItWorkData);
        // setPackageSubscriptionApiData(packageData[0]);
        // setPackageTrialApiData(packageData[1]);
        // setHowItSignupApiData(howItSignupData);
        setAllTreatmentsApiData(allTreatmentsData);
        setAboutApiApiData(aboutData[0]);
        setOurTeamApiData(ourTeamData);
        setGalleryData(galleryData);
        setSeoData(seoData[0]);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.error("Error fetching data:", err);
      }
    };

    fetchData();
  }, []);



  return (
    <MyContext.Provider value={myData}>
      <HelmetProvider>
        <Routes>
          <Route path="/" element={<Layout businessApiData={businessApiData} />}>
            <Route index element={<Home />} />
            <Route path="about" element={<About />} />
            <Route path="doctors" element={<Doctors />} />
            <Route path="doctors/:doctorId" element={<DoctorDetails />} />
            <Route path="our-treatments" element={<Treatments />} />
            <Route
              path="our-treatments/:service_slug"
              element={<TreatmentDetalis />}
            />
            <Route path="blog" element={<Blog />} />
            <Route
              path={`${process.env.PUBLIC_URL + "/blog/:blog_slug"}`}
              element={<BlogDetails />}
            />
            <Route path="appointments" element={<Appointments />} />
            <Route path="departments" element={<Departments />} />
            <Route path="awards" element={<Awards />} />
            <Route
              path="departments/:departmentId"
              element={<DepartmentDetails />}
            />
            <Route path="pricing-plan" element={<PricingPlan />} />
            <Route path="gallery" element={<Gallery />} />
            <Route path="timetable" element={<Timetable />} />
            <Route path="contact" element={<Contact />} />
            <Route path="faq" element={<Faq />} />
            <Route path="cookiesPolicy" element={<CookiesPolicy />} />
            <Route path="privacyPolicy" element={<PrivacyPolicy />} />
            <Route path="termsandConditions" element={<TermsandConditions />} />
          </Route>
          <Route element={<Layout2 />}>
            <Route path="home-v2" element={<HomeStyle2 />} />
            <Route path="home-v6" element={<HomeStyle6 />} />
          </Route>
          <Route path="home-v3" element={<Layout3 />}>
            <Route index element={<HomeStyle3 />} />
          </Route>
          <Route path="home-v4" element={<Layout4 />}>
            <Route index element={<HomeStyle4 />} />
          </Route>
          <Route path="home-v5" element={<Layout5 />}>
            <Route index element={<HomeStyle5 />} />
          </Route>
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </HelmetProvider>
    </MyContext.Provider>
  );
}

export default App;
