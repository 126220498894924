import React from 'react';
import Rating from '../Rating';
import { IMAGE_BASE_URL } from '../../lib/api';

export default function TestimonialStyle4({
  testimonial,
  ratingNumber,
  testimonial_image,
  alt_tag,
  name,
  avatarDesignation,
}) {
  console.log("testimonial_image: ", testimonial_image);
  return (
    <div className="cs_testimonial cs_style_4 cs_radius_20">
      <div className="cs_testimonial_meta">
        <div className="cs_testimonial_avatar">
          <img src={IMAGE_BASE_URL + testimonial_image} alt="Avatar" />
        </div>
        <div className="cs_testimonial_meta_right">
          <h3 className="cs_fs_24 cs_semibold m-0">{name}</h3>
          {/* <p className="cs_heading_color m-0">{avatarDesignation}</p> */}
        </div>
      </div>
      <div className="cs_testimonial_text cs_heading_color cs_fs_20">
        <p>{testimonial}</p>
      </div>
      <Rating ratingNumber={ratingNumber} />
    </div>
  );
}
