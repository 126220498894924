import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import Section from "../Section";
import Breadcrumb from "../Breadcrumb";
import BannerSectionStyle9 from "../Section/BannerSection/BannerSectionStyle9";
import { Icon } from "@iconify/react";
import Spacing from "../Spacing";
import Post from "../Post";
import Sidebar from "../Sidebar";
import AuthorWidget from "../Widget/AuthorWidget";
import CommentsWidget from "../Widget/CommentsWidget";
import ReplyWidget from "../Widget/ReplyWidget";
import { pageTitle } from "../../helpers/PageTitle";
import MyContext from "../../lib/MyContext";
import { IMAGE_BASE_URL } from "../../lib/api";

export default function TreatmentDetalis() {
  const { allTreatmentsApiData } = useContext(MyContext);
  pageTitle("Blog Details");
  const [blogTagArray, setBlogTagArray] = useState([]);
  // const [blogApiData, setBlogApiData] = useState([]);
  const { service_slug } = useParams();
  const serviceFilter = allTreatmentsApiData?.find(
    (blog) => blog.service_slug === service_slug
  );
  console.log("serviceFilter: ", serviceFilter);
  useEffect(() => {
    // Convert the string to an array when the component mounts
    setBlogTagArray(serviceFilter?.blog_tag?.split(","));
  }, [serviceFilter]);
  return (
    <>
      <Section topMd={85} bottomMd={54} bottomLg={54}>
        <Breadcrumb title={serviceFilter?.service_title} />
      </Section>
      <div className="container">
        <div className="cs_blog_details_info">
          <div className="cs_blog_details_info_left">
            <div className="cs_blog_details_date">
              {serviceFilter?.service_short_des}
            </div>
          </div>
        </div>
        <Spacing md="55" />
        <img
          src={IMAGE_BASE_URL + serviceFilter?.service_image}
          alt="Blog Details"
          className="w-75 cs_radius_20 text-center"
        />
        <Spacing md="90" lg="50" />
        <div className="row">
          <div className="col-lg-8">
            <div className="cs_blog_details">
              <div
                dangerouslySetInnerHTML={{
                  __html: serviceFilter?.long_description,
                }}
              ></div>
            </div>
          </div>
          {/* <div className="col-lg-4">
            <Sidebar allTreatmentsApiData={allTreatmentsApiData} />
          </div> */}
        </div>
      </div>
    </>
  );
}
