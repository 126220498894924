import React, { useContext } from "react";
import BannerSectionStyle5 from "../Section/BannerSection/BannerSectionStyle5";
import ContactForm from "../ContactForm";
import Section from "../Section";
import ContactInfoSection from "../Section/ContactInfoSection";
import { pageTitle } from "../../helpers/PageTitle";
import MyContext from "../../lib/MyContext";
import SEO from "../Seo";
export default function Contact() {
  const { seoData } = useContext(MyContext);
  pageTitle("Contact");
  return (
    <>
      <SEO
        title={seoData?.contact_meta_title}
        description={seoData?.contact_meta_desc}
        name="Shine32"
        keywords={seoData?.contact_meta_keywords}
        author={seoData?.contact_meta_author}
        type="Doctor Website"
      />
      <BannerSectionStyle5
        bgUrl="/images/contact/banner_bg.svg"
        imgUrl="/images/contact/banner_img.png"
        title="Contact Us"
        subTitle="Kindly reach us to get the fastest response and treatment"
      />
      <div className="container cs_mt_minus_110">
        <div className="row">
          <div className="col-lg-10 offset-lg-1">
            <ContactForm />
          </div>
        </div>
      </div>
      <Section
        topMd={85}
        topLg={150}
        topXl={100}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
        <ContactInfoSection sectionTitle="Find Us Here" />
      </Section>
    </>
  );
}
