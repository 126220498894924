import React from "react";
import { Link } from "react-router-dom";
import { IMAGE_BASE_URL } from "../../lib/api";
export default function PostStyle2({
  blog_title,
  blog_image,
  blog_date,
  blog_slug,
  blog_short_des,
}) {
  return (
    <div className="cs_post cs_style_2 cs_radius_20 overflow-hidden">
      <Link to={`/blog/${blog_slug}`} className="cs_post_thumb cs_view_mouse">
        <img src={IMAGE_BASE_URL + blog_image} alt="Post" />
      </Link>
      <div className="cs_post_info">
        <div>
          <h2 className="cs_post_title cs_semibold cs_fs_24">
            <Link to={`/blog/${blog_slug}`}>{blog_title}</Link>
          </h2>
          <p class="cs_iconbox_text">{blog_short_des}</p>
          <div className="cs_posted_by">{blog_date} - Shine32</div>
        </div>
      </div>
    </div>
  );
}
