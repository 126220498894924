import React, { useState, useEffect, useContext } from "react";
import FaqSectionStyle3 from "../Section/FaqSection/FaqSectionStyle3";
import Section from "../Section";
import {
  IMAGE_BASE_URL,
  websiteSettingApi,
  allHeadingApi,
} from "../../lib/api";
import MyContext from "../../lib/MyContext";
import { pageTitle } from "../../helpers/PageTitle";
import SEO from "../Seo";
const Faq = () => {
  const [loading, setLoading] = useState(false);

  const { faqsApiData, seoData } = useContext(MyContext);

  pageTitle("Home");
  const [websiteSettingApiData, setWebsiteSettingApiData] = useState([]);
  const [allHeadingApiData, setAllHeadingApiData] = useState([]);
  useEffect(() => {
    // Function to fetch data from both APIs
    const fetchData = async () => {
      setLoading(true);
      try {
        // Calling businessApi and homeApi simultaneously
        const [websiteSettingData, allHeadingData] = await Promise.all([
          websiteSettingApi(),
          allHeadingApi(),
        ]);
        // Updating state with the API data
        setWebsiteSettingApiData(websiteSettingData[0]);
        setAllHeadingApiData(allHeadingData[0]);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching data:", err);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <SEO
        title={seoData?.faq_meta_title}
        description={seoData?.faq_meta_desc}
        name="Shine32"
        keywords={seoData?.faq_meta_keywords}
        author={seoData?.faq_meta_author}
        type="Doctor Website"
      />
      <div
        className=""
        style={{
          backgroundImage: `url(${IMAGE_BASE_URL + allHeadingApiData?.pp_banner
            }`,
          height: "50vh",
        }}
      ></div>
      <Section topMd={85} topLg={145} topXl={105}>
        <FaqSectionStyle3
          data={faqsApiData}
          sectionTitle="Usually Asked"
          sectionTitleUp="What People"
          sectionTitleDown=""
          sectionSubTitle=""
        />
      </Section>
    </>
  );
};

export default Faq;
