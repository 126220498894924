import React, { useState, useEffect } from "react";
import { websiteSettingApi, allHeadingApi, IMAGE_BASE_URL } from "../../lib/api";
const PrivacyPolicy = () => {
  const [loading, setLoading] = useState(false);
  const [websiteSettingApiData, setWebsiteSettingApiData] = useState([]);
  const [allHeadingApiData, setAllHeadingApiData] = useState([]);
  useEffect(() => {
    // Function to fetch data from both APIs
    const fetchData = async () => {
      setLoading(true);
      try {
        // Calling businessApi and homeApi simultaneously
        const [websiteSettingData, allHeadingData] = await Promise.all([
          websiteSettingApi(),
          allHeadingApi(),
        ]);
        // Updating state with the API data
        setWebsiteSettingApiData(websiteSettingData[0]);
        setAllHeadingApiData(allHeadingData[0]);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching data:", err);
      }
    };

    fetchData();
  }, []);

  console.log("websiteSettingData: ", websiteSettingApiData);


  return (
    <>
      <div
        className=""
        style={{
          backgroundImage: `url(${IMAGE_BASE_URL + allHeadingApiData?.pp_banner
            }`,
          height: "50vh",
        }}
      ></div>
      <div className="container mt-5" dangerouslySetInnerHTML={{ __html: websiteSettingApiData?.privacy_policy }}></div>

    </>
  );
};

export default PrivacyPolicy;
