import React from "react";
import ReactLoading from "react-loading";

const LOADING = () => (
    <div style={{
        width: "100%",
        height: "90vh",
    }}>
        <div style={{
            position: "absolute",
            top: "45%",
            left: "50%",
            transform: "trnaslate(-50%,-50%)"
        }}>
            <ReactLoading type="bars" color="#307bc4" />
        </div>
    </div>
);

export default LOADING;
