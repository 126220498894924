import React from "react";
import { IMAGE_BASE_URL } from "../../lib/api";
import { Link } from "react-router-dom";
export default function IconBoxStyle1({
  service_title,
  service_short_des,
  service_image,
  service_thumbnail,
  service_slug,
}) {
  const path = window.location.pathname;
  // console.log("path: ", path);
  return (
    <Link
      to={
        path == "/our-treatments"
          ? `${service_slug}`
          : `our-treatments/${service_slug}`
      }
    >
      <div className="cs_style_1 cs_shadow_1 cs_radius_15 p-3">
        <div className="cs_iconbox_top">
          <div className=" cs_radius_15 pb-2">
            <img src={IMAGE_BASE_URL + service_thumbnail} alt="Icon" />
          </div>
          <h2 className="cs_iconbox_title cs_medium cs_fs_20 m-0 pb-2">
            {service_title}
          </h2>
        </div>
        <p className="cs_iconbox_text">{service_short_des}</p>
      </div>
    </Link>
  );
}
