import React from "react";
import SectionHeading from "../../SectionHeading";
import Spacing from "../../Spacing";
import IconBoxStyle1 from "../../IconBox/IconBoxStyle1";

export default function AwardSection({ sectionTitle, data }) {
  console.log("data: ", data);
  return (
    <div className="container">
      <SectionHeading title={sectionTitle} />
      <p className="cs_iconbox_text">
        From routine checkups to advanced procedures, Shine 32 offers a full
        spectrum of dental services tailored to your unique needs. Discover
        gentle expertise, cutting-edge technology, and personalized care in one
        convenient location. Book your consultation today and experience the
        difference!
      </p>
      <Spacing md="72" lg="50" />
      <div className="row gy-4">
        {data?.map((item, index) => (
          <div className="col-xxl-3 col-md-6" key={index}>
            <IconBoxStyle1 {...item} />
          </div>
        ))}
      </div>
    </div>
  );
}
