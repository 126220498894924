import { Icon } from "@iconify/react";
import React from "react";
import ModalImage from "react-modal-image";
import { IMAGE_BASE_URL } from "../../lib/api";

export default function Portfolio({ gallery_image }) {
  console.log("gallery_image: ", gallery_image);
  return (
    <div className="cs_portfolio cs_style_1 cs_radius_20 overflow-hidden">
      <div
        className="cs_portfolio_img d-block cs_bg_filed st_lightbox_item"
        style={{
          backgroundImage: `url(${IMAGE_BASE_URL + gallery_image})`,
        }}
      >
        <ModalImage
          small={IMAGE_BASE_URL + gallery_image}
          large={IMAGE_BASE_URL + gallery_image}
          alt="Gallery"
        />
        <span className="cs_link_hover">
          <i>
            <Icon icon="fa6-solid:arrows-up-down-left-right" />
          </i>
        </span>
      </div>
    </div>
  );
}
