import React, { useContext } from "react";
import BannerSectionStyle3 from "../Section/BannerSection/BannerSectionStyle3";
import BannerSectionStyle4 from "../Section/BannerSection/BannerSectionStyle4";
import Section from "../Section";
import AwardSectionStyle2 from "../Section/AwardSection/AwardSectionStyle2";
import { pageTitle } from "../../helpers/PageTitle";
import AboutSectionStyle5 from "../Section/AboutSection/AboutSectionStyle5";
import MyContext from "../../lib/MyContext";
import { IMAGE_BASE_URL } from "../../lib/api";
import SEO from "../Seo";
const awardData = [
  {
    iconUrl: "/images/icons/1.png",
    title: "Guilin Woodpecker Medical Instrument Co, LTD",
  },
  {
    iconUrl: "/images/icons/2.png",
    title: "Academy Of Dental Career Inc",
  },
  {
    iconUrl: "/images/icons/3.png",
    title: "Authorised Center for ADC Inc Chain Of Clinic ",
  },
  {
    iconUrl: "/images/icons/4.png",
    title: "Nominated for ADC INC PASSIONATE ENDODONTICS CONTEST 20219",
  },
  {
    iconUrl: "/images/icons/5.png",
    title: "Dental Photography School",
  },
  {
    iconUrl: "/images/icons/6.png",
    title: "INDIAN DENTAL INSTITUTION,UP STATE ",
  },
  {
    iconUrl: "/images/icons/7.png",
    title: "INDIAN DENTAL ASSOCIATION",
  },
  {
    iconUrl: "/images/icons/8.png",
    title: "PT,RAVISHANKAR SHUKLA UNIVERSITY,RAIPUR",
  },
  {
    iconUrl: "/images/icons/9.png",
    title: "Online Dentistry",
  },
  {
    iconUrl: "/images/icons/10.png",
    title: "RUNGTA COLLEGE OF DENTAL SCIENCES AND RESEARCH ",
  },
];

export default function About() {
  pageTitle("About");
  const { aboutApiApiData, seoData } = useContext(MyContext);
  return (
    <>
      <SEO
        title={seoData?.about_meta_title}
        description={seoData?.about_meta_desc}
        name="Shine32"
        keywords={seoData?.about_meta_keywords}
        author={seoData?.about_meta_author}
        type="Doctor Website"
      />
      <BannerSectionStyle3
        bgUrl="/images/about/banner_bg.svg"
        imgUrl={IMAGE_BASE_URL + aboutApiApiData?.about_banner}
        title="Welcome to <br />ProHealth Medical & Healthcare Center"
        subTitle="Your Partner in Health and Wellness"
      />

      <Section topMd={85} topLg={150} topXl={110}>
        <AboutSectionStyle5
          imgUrl={IMAGE_BASE_URL + aboutApiApiData?.about_img}
          title={aboutApiApiData?.title}
          subTitle={aboutApiApiData?.long_des}
          spiningImgUrl="/images/home_1/about_mini.svg"
          titleUp="ABOUT US"
        />
      </Section>

      <Section topMd={85} topLg={140} topXl={100}>
        <AwardSectionStyle2
          sectionTitle="Winning Awards and <br />Recognition"
          sectionTitleUp="AWARDS"
          sectionSubTitle="We have been recognized for our commitment to <br />excellence in healthcare."
          data={awardData}
        />
      </Section>
      <Section
        className="cs_footer_margin_0"
        topMd={58}
        topLg={140}
        topXl={100}
      >
        <BannerSectionStyle4
          bgUrl="images/about/banner_bg_2.jpeg"
          title="Don’t Let Your Health <br />Take a Backseat!"
          subTitle="Schedule an appointment with one of our experienced <br />medical professionals today!"
          center
        />
      </Section>
    </>
  );
}
