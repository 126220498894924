import React from 'react';
import SectionHeading from '../../SectionHeading';
import Spacing from '../../Spacing';
import IconBoxStyle5 from '../../IconBox/IconBoxStyle5';

export default function AwardSectionStyle2({
  sectionTitle,
  sectionTitleUp,
  sectionSubTitle,
  data,
}) {
  return (
    <div className="container">
      <SectionHeading
        title={sectionTitle}
        titleUp={sectionTitleUp}
        subTitle={sectionSubTitle}
        center
      />
      <Spacing md="72" lg="50" />
      <div className="row cs_gap_y_50">
        {data?.map(({ title, iconUrl }, index) => (
          <div className="col-xxl-3 col-md-6" key={index}>
            <div className="d-flex flex-column">
              <img src={iconUrl} alt="Icon" />
              <h2 className="cs_iconbox_title m-0 cs_fs_20 cs_medium text-center mt-2">{title}</h2>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
