import React, { useContext } from "react";
import BannerSectionStyle9 from "../Section/BannerSection/BannerSectionStyle9";
import Section from "../Section";
import BlogSectionStyle2 from "../Section/BlogSection/BlogSectionStyle2";
import Breadcrumb from "../Breadcrumb";
import { pageTitle } from "../../helpers/PageTitle";
import MyContext from "../../lib/MyContext";
import { IMAGE_BASE_URL } from "../../lib/api";

export default function Blog() {
  const { blogApiData } = useContext(MyContext);
  pageTitle("Blog");
  return (
    <>
      <Section topMd={85} bottomMd={96} bottomLg={70}>
        <Breadcrumb title="Our Blogs" />
      </Section>
      <Section bottomMd={200} bottomLg={150} bottomXl={110}>
        <BlogSectionStyle2 data={blogApiData} />
      </Section>
      {/* <Section className="cs_footer_margin_0">
        <BannerSectionStyle9
          title={blogApiData?.blog_title}
          subTitle={blogApiData?.blog_short_des}
          imgUrl={IMAGE_BASE_URL + blogApiData?.blog_image}
        />
      </Section> */}
    </>
  );
}
