import { Icon } from '@iconify/react';
import React from 'react';

export default function ContactInfoWidget({ businessApiData }) {
  return (
    <ul className="cs_contact_widget">
      <li>
        <i className="cs_accent_bg">
          <Icon icon="ep:location" />
        </i>
        {businessApiData?.address}
      </li>
      <li>
        <i className="cs_accent_bg">
          <Icon icon="fluent:call-24-regular" />
        </i>
        {businessApiData?.phone}
      </li>
      <li>
        <i className="cs_accent_bg">
          <Icon icon="bi:envelope" />
        </i>
        {businessApiData?.email}
      </li>
    </ul>
  );
}
