import React, { useContext } from "react";
import Banner from "../Section/BannerSection";
import Section from "../Section";
import BlogSectionStyle4 from "../Section/BlogSection/BlogSectionStyle4";
import FaqSectionStyle3 from "../Section/FaqSection/FaqSectionStyle3";
import AwardSection from "../Section/AwardSection";
import { pageTitle } from "../../helpers/PageTitle";
import HeroStyle2 from "../Hero/HeroStyle2";
import AboutSectionStyle4 from "../Section/AboutSection/AboutSectionStyle4";
import AboutSectionStyle6 from "../Section/AboutSection/AboutSectionStyle6";
import TestimonialSectionStyle4 from "../Section/TestimonialSection/TestimonialSectionStyle4";
import TeamSectionStyle4 from "../Section/TeamSection/TeamSectionStyle4";
import AppointmentSectionStyle2 from "../Section/AppointmentSection/AppointmentSectionStyle2";
import BannerSectionStyle2 from "../Section/BannerSection/BannerSectionStyle2";
import BrandsSectionStyle2 from "../Section/BrandsSection/BrandsSectionStyle2";
import MyContext from "../../lib/MyContext";
import { IMAGE_BASE_URL } from "../../lib/api";
import LOADING from "../Loading";
const awardData = [
  {
    iconUrl: "/images/icons/1.png",
    title: "Guilin Woodpecker Medical Instrument Co, LTD",
  },
  {
    iconUrl: "/images/icons/2.png",
    title: "Academy Of Dental Career Inc",
  },
  {
    iconUrl: "/images/icons/3.png",
    title: "Authorised Center for ADC Inc Chain Of Clinic ",
  },
  {
    iconUrl: "/images/icons/4.png",
    title: "Nominated for ADC INC PASSIONATE ENDODONTICS CONTEST 20219",
  },
  {
    iconUrl: "/images/icons/5.png",
    title: "Dental Photography School",
  },
  {
    iconUrl: "/images/icons/6.png",
    title: "INDIAN DENTAL INSTITUTION,UP STATE ",
  },
  {
    iconUrl: "/images/icons/7.png",
    title: "INDIAN DENTAL ASSOCIATION",
  },
  {
    iconUrl: "/images/icons/8.png",
    title: "PT,RAVISHANKAR SHUKLA UNIVERSITY,RAIPUR",
  },
  {
    iconUrl: "/images/icons/9.png",
    title: "Online Dentistry",
  },
  {
    iconUrl: "/images/icons/10.png",
    title: "RUNGTA COLLEGE OF DENTAL SCIENCES AND RESEARCH ",
  },
];

export default function Home() {
  const {
    loading,
    allTreatmentsApiData,
    homeApiData,
    aboutApiApiData,
    testimonialApiData,
    ourTeamApiData,
    blogApiData,
    faqsApiData,
  } = useContext(MyContext);

  pageTitle("Home");
  var treatmentsApiData = allTreatmentsApiData.slice(0, 4);
  var faqsApiDataSlice = faqsApiData.slice(0, 4);

  if (loading) {
    return <LOADING />
  }

  // console.log("blogApiData: ", blogApiData);
  return (
    <>
      <HeroStyle2
        title={homeApiData?.title_one}
        subTitle={homeApiData?.short_des_one}
        bgUrl={IMAGE_BASE_URL + homeApiData?.slider_one}
        imgUrl="/images/home_2/patents.png"
        videoBtnText="See how we work"
        videoUrl="#"
        btnText="Shine32"
        btnUrl="/"
        funfactList={[
          { number: "10+", title: "Years of experience" },
          { number: "98%", title: "Patient satisfaction rating" },
          // { number: "5000+", title: "Patients served annually" },
          { number: "10+", title: "Healthcare providers on staff" },
        ]}
      />
      {/* Start Feature Section */}
      {/* <Section
        topMd={85}
        topLg={140}
        topXl={100}
        bottomMd={185}
        bottomLg={140}
        bottomXl={100}
      >
        <FeaturesSection sectionTitle="Our Values" data={featureListData} />
      </Section> */}
      {/* Start Award Section */}

      <Section topMd={85} topLg={140} topXl={100}>
        <AwardSection sectionTitle="Our Treatments" data={treatmentsApiData} />
      </Section>
      {/* End Award Section */}

      {/* End Feature Section */}
      <Section topMd={85} topLg={150} topXl={110}>
        <AboutSectionStyle4
          imgUrl="/images/home_3/about.png"
          spiningImgUrl="/images/home_1/about_mini.png"
          titleUp="ABOUT US"
          title={aboutApiApiData?.title}
          subTitle={aboutApiApiData?.short_dec}
        />
      </Section>
      {/* Start Departments Section */}

      <Section topMd={85} topLg={150} topXl={105}>
        <TestimonialSectionStyle4
          sectionTitle="Real Patients, Real Smiles: Read what our patients say about Shine 32"
          sectionTitleUp="WHAT OUR PATIENTS SAY"
          avatarListTitle="150+ patients are reviews about ProHealth"
          avatarList={[
            { imgUrl: "/images/home_4/avatar_1.png" },
            { imgUrl: "/images/home_4/avatar_2.png" },
            { imgUrl: "/images/home_4/avatar_3.png" },
            { imgUrl: "/images/home_4/avatar_4.png" },
            { imgUrl: "/images/home_4/avatar_5.png" },
          ]}
          data={testimonialApiData}
        />
      </Section>

      {/* Start Banner Section */}
      <Section>
        <Banner
          bgUrl="images/home_1/cta_bg.png"
          imgUrl="images/home_1/cta_img.png"
          title="Don’t Let Your Health Take a Backseat!"
          subTitle="Schedule an appointment with one of our experienced medical professionals today!"
        />
      </Section>
      {/* End Banner Section */}
      {/*Start our teams */}

      {/* <Section topMd={85} topLg={145} topXl={105}>
        <TeamSectionStyle4
          sectionTitle="Experts Doctor"
          sectionTitleUp="MEET OUR"
          data={ourTeamApiData}
        />
      </Section> */}
      <Section topMd={85} topLg={150} topXl={110}>
        <AboutSectionStyle6
          imgUrl="/images/about/sukirti_mandal.jpg"
          // spiningImgUrl="/images/home_1/about_mini.png"
          // titleUp="ABOUT US"
          title="Dr. Sukirti Mandal Biswas"
          subTitle={aboutApiApiData?.short_dec}
        />
      </Section>

      {/*End our teams */}

      {/* <Section topMd={85} topLg={150} topXl={110}>
        <DepartmentSection
          sectionTitle="Departments"
          bgUrl="images/home_1/department_bg.svg"
          data={departmentData}
        />
      </Section> */}

      {/* End Departments Section */}
      <Section topMd={85} topLg={145} topXl={105}>
        <AppointmentSectionStyle2
          bgUrl="/images/home_2/appointment_bg.svg"
          imgUrl="/images/home_2/appointment_img.png"
          sectionTitle="Contact Us Now"
        // sectionTitleUp="BOOK AN"
        />
      </Section>
      {/* Start Banner Section */}
      <Section topMd={85} topLg={145} topXl={105}>
        <BannerSectionStyle2
          bgUrl="/images/home_2/cta_bg.png"
          title="Don’t Let Your Health Take a Backseat!"
          subTitle="Schedule an appointment with one of our <br />
          experienced medical professionals today!"
        />
      </Section>
      {/* End Banner Section */}
      {/* Start Blog Section */}
      <Section topMd={85} topLg={120} topXl={70}>
        <BlogSectionStyle4
          sectionTitle="Latest Update"
          sectionTitleUp="BLOG POSTS"
          data={blogApiData}
        />
      </Section>
      {/* End Blog Section */}

      {/* Start FAQ Section */}
      <Section topMd={85} topLg={145} topXl={105}>
        <FaqSectionStyle3
          data={faqsApiDataSlice}
          sectionTitle="Usually Asked"
          sectionTitleUp="What People"
          sectionTitleDown=""
          sectionSubTitle=""
        />
      </Section>
      {/* End FAQ Section */}
      {/* Start Brand Section */}
      <Section
        topMd={85}
        topLg={150}
        topXl={110}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
        <BrandsSectionStyle2 data={awardData} />
      </Section>
      {/* End Brand Section */}
    </>
  );
}
