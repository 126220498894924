import React, { useEffect, useRef, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import axios from "axios";
import Swal from "sweetalert2";
import { Icon } from '@iconify/react';
import DatePicker from 'react-datepicker';



export default function MyAppointmentBook() {
    const [loading, setLoading] = useState(false)
    const [selectedDate, setSelectedDate] = useState(null);
    const [dateOfBirth, setDateOfBirth] = useState(null)
    const form = useRef();
    const onSubmit = async (e) => {
        setLoading(true)
        e.preventDefault();
        const { name, dob, gender, email, phone, p_date, p_time, visit_reason } = e.target.elements;

        let conFom = {
            name: name?.value,
            dob: dob?.value,
            gender: gender?.value,
            email: email?.value,
            phone: phone?.value,
            p_date: p_date?.value,
            p_time: p_time?.value,
            visit_reason: visit_reason?.value,
        };

        console.log("conFom: ", conFom);

        if (conFom.name !== "" && conFom.email !== "") {
            const url = 'https://cms.shine32.in/api/appointment';
            let fData = new FormData();
            fData.append('name', name?.value);
            fData.append('dob', dob?.value);
            fData.append('gender', gender?.value);
            fData.append('email', email?.value);
            fData.append('phone', phone?.value);
            fData.append('p_date', p_date?.value);
            fData.append('p_time', p_time?.value);
            fData.append('visit_reason', visit_reason?.value);
            setLoading(true)
            axios.post(url, fData).then((response) => {
                setLoading(false)
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Submit Successfully',
                    showConfirmButton: true,
                    timer: 1500
                })
                form.current.reset();

            }).catch((error) => {
                setLoading(false)
                console.log(error);
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'something went wrong',
                })
            });
        } else {
            setLoading(false)
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'please fill all the field!',
            })
        }
    };
    return (
        <form className="row"
            ref={form}
            onSubmit={onSubmit}>
            <div className="col-lg-6">
                <label className="cs_input_label cs_heading_color">Name</label>
                <input type="text" className="cs_form_field" placeholder="David John" name='name' />
                <div className="cs_height_42 cs_height_xl_25" />
            </div>
            <div className="col-lg-6">
                <label className="cs_input_label cs_heading_color">
                    Date of birth
                </label>

                <div className="cs_with_icon_input">
                    <DatePicker
                        name='dob'
                        selected={dateOfBirth}
                        onChange={date => setDateOfBirth(date)}
                        dateFormat="dd/MM/yyyy"
                        minDate={new Date()}
                        isClearable
                        placeholderText="dd/mm/yyyy"
                    />
                    <i>
                        <Icon icon="fa6-solid:calendar-days" />
                    </i>
                </div>
                <div className="cs_height_42 cs_height_xl_25" />
            </div>
            <div className="col-lg-6">
                <label className="cs_input_label cs_heading_color">Phone Number</label>
                <input
                    type="text"
                    className="cs_form_field"
                    placeholder="(123) 456 - 789"
                    name='phone'
                />
                <div className="cs_height_42 cs_height_xl_25" />
            </div>
            <div className="col-lg-6">
                <label className="cs_input_label cs_heading_color">Email</label>
                <input type="email" className="cs_form_field" placeholder="David John" name='email' />
                <div className="cs_height_42 cs_height_xl_25" />
            </div>
            <div className="col-lg-6">
                <label className="cs_input_label cs_heading_color">
                    Preferred Date
                </label>

                <div className="cs_with_icon_input">
                    <DatePicker
                        name='p_date'
                        selected={selectedDate}
                        onChange={date => setSelectedDate(date)}
                        dateFormat="dd/MM/yyyy"
                        minDate={new Date()}
                        isClearable
                        placeholderText="dd/mm/yyyy"
                    />
                    <i>
                        <Icon icon="fa6-solid:calendar-days" />
                    </i>
                </div>
                <div className="cs_height_42 cs_height_xl_25" />
            </div>
            <div className="col-lg-6">
                <label className="cs_input_label cs_heading_color">
                    Preferred Time
                </label>
                <div className="cs_with_icon_input">
                    <input
                        name='p_time'
                        type="time"
                        className="cs_form_field cs_timepicker"
                        placeholder="10:00AM"
                    />
                    <i>
                        <Icon icon="fa6-regular:clock" />
                    </i>
                </div>
                <div className="cs_height_42 cs_height_xl_25" />
            </div>
            <div className="col-lg-12">
                <label className="cs_input_label cs_heading_color">Message</label>
                <textarea
                    cols={30}
                    rows={10}
                    className="cs_form_field"
                    placeholder="Write something..."
                    defaultValue={''}
                    name='visit_reason'
                />
                <div className="cs_height_42 cs_height_xl_25" />
            </div>
            <div className="col-lg-12">
                <label className="cs_input_label cs_heading_color">
                    Gender
                </label>
                <div className="cs_radio_group">
                    <div className="cs_radio_wrap">
                        <input
                            className="cs_radio_input"
                            type="radio"
                            name="gender"
                            id="routineCheckup"
                            defaultValue="routineCheckup"
                        />
                        <label className="cs_radio_label" htmlFor="routineCheckup">
                            Male
                        </label>
                    </div>
                    <div className="cs_radio_wrap">
                        <input
                            className="cs_radio_input"
                            type="radio"
                            name="gender"
                            id="newPatientVisit"
                            defaultValue="newPatientVisit"
                            defaultChecked=""
                        />
                        <label className="cs_radio_label" htmlFor="newPatientVisit">
                            Female
                        </label>
                    </div>
                </div>
                <div className="cs_height_42 cs_height_xl_25" />
            </div>
            <div className="col-lg-12">
                <button className="cs_btn cs_style_1">
                    <span>Submit</span>
                    <i>
                        <img src="/images/icons/arrow_white.svg" alt="Icon" />
                        <img src="/images/icons/arrow_white.svg" alt="Icon" />
                    </i>
                </button>
            </div>

        </form>
    );
}
